// import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom'
import Navbar from '../components/Navbar.js'

import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const Home = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div>
      <Navbar />

      <Container fluid>
        <Row style={{ minWidth: '1000px' }}>

          {/* <Col className="vh-100" style={{ backgroundColor: "rgb(218, 227, 243)", paddingTop: "20px", maxWidth: '360px' }}>
                    </Col> */}

          {/* <Col md="auto" style={{ padding: "0px" }}> */}
          <Col style={{ padding: "0px" }}>
            <div style={{ paddingLeft: '40px', paddingRight: '40px', fontSize: "16px", paddingTop: "20px" }}>
              <p>
                The NOAA NESDIS (see the Acronym List below) liquid equivalent snowfall rate (SFR) product is retrieved from passive
                microwave measurements taken by sensors onboard a constellation of polar-orbiting satellites operated
                by NOAA, NASA, EUMETSAT, and DoD. Microwave can penetrate clouds and sense in-cloud
                hydrometeors, hence it is suitable for precipitation retrieval. The SFR product is derived from two
                algorithms: a machine learning snowfall detection model and a 1DVAR-based snowfall rate estimation
                algorithm.
              </p>
              <p>
                The SFR product first went into operation in 2012. Currently, NESDIS produces the product operationally
                from ATMS and AMSU-A/MHS onboard 5 satellites: NOAA-20, S-NPP, NOAA-19, Metop-C, and Metop-B.
                In addition, CISESS at the University of Maryland produces SFR for the above satellites using direct
                broadcast (DB) data obtained from the <a href="https://cimss.ssec.wisc.edu/" rel="noreferrer noopener" target="_blank">Cooperative
                  Institute for Meteorological Satellite Studies (CIMSS)</a>, University of Wisconsin-Madison
                and the <a href="https://gina.alaska.edu/" rel="noreferrer noopener" target="_blank">Geographic Information Network of Alaska
                  (GINA)</a>, University of Alaska Fairbanks. The DB-based SFR has a low latency that
                enables product applications for nowcasting and short-term weather forecasting. CISESS also derives
                experimental SFR from GMI and SSMIS onboard GPM and DMSP-F16, -F17, and F18, respectively.
              </p>
              <p>
              {/* <span style={{ color: "red", }}><em>*New*</em> </span> The newest JPSS satellite, NOAA-21, successfully
                launched on November 10, 2022. The first light NOAA-21 SFR image is <News />. The product has not been
                validated yet. */}
                {/* <span style={{ color: "red", }}><em>*New*</em> </span> A new function has been added to this website to generate gif movies 
                (under "Product" menu or <a href="http://sfr.umd.edu/?page=GenerateGif">here</a>). Please note that the current version only 
                generates GIFs with a maximum of 20 frames. */}
              </p>
              <p>
                Besides SFR, CISESS also produces a radar-satellite merged snowfall rate product, mSFR. It combines
                the NESDIS SFR product with the NSSL MRMS instantaneous radar snowfall rate estimates. This is a
                spatiotemporally more advanced product that benefits from satellite's broad spatial coverage and
                radar's frequent observations.
              </p>
              <b>The products shown on this website are produced from the CISESS DB-based processing system.</b>
              <Container fluid>
                <Row >
                  <Col style={{ paddingLeft: "0px" }} md="auto">
                    <ul style={{ textDecoration: "underline", textDecorationColor: "blue", color: "blue", cursor: "pointer" }}>
                      <li onClick={() => setSearchParams({ page: "SFR-CONUS" })}>Direct Broadcast SFR - CONUS (Starting on 10/01/2020)</li>
                      <li onClick={() => setSearchParams({ page: "SFR-Alaska" })}>Direct Broadcast SFR - Alaska (Starting on 10/01/2020)</li>
                      <li onClick={() => setSearchParams({ page: "mSFR-CONUS" })}>mSFR - CONUS (01/01/2022 - 04/30/2022 and 10/01/2022 - current)</li>
                      <li><a style={{ color: "blue" }}
                        href="sfr_v0"
                        target="_blank" rel="noreferrer noopener">Previous SFR Page</a></li>
                    </ul>
                  </Col>
                  <Col>
                  </Col>
                </Row>
              </Container>

              <b>Acronym List</b><br></br>
              <p>AMSU-A - Advanced Microwave Sounding Unit-A<br />
                ATMS - Advanced Technology Microwave Sounder<br />
                CISESS - Cooperative Institute for Satellite Earth System Studies<br />
                GMI - GPM Microwave Imager<br />
                MHS - Microwave Humidity Sounder<br />
                MRMS - Multi-Radar/Multi-Sensor System<br />
                NESDIS - National Environmental Satellite, Data, and Information Service<br />
                NSSL - National Severe Storms Laboratory<br />
                SSMI/S - Special Sensor Microwave Imager / Sounder</p>
            </div>
          </Col>

        </Row>
      </Container>
    </div>
  );
}
export default Home;


function News() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" style={{ background: "white", color: "blue", border: "0", padding: "0" }} onClick={handleShow}>
        HERE
      </Button>

      <Modal size="lg" show={show} onHide={handleClose}>
        {/* <Modal.Header closeButton>
            <Modal.Title>Modal heading</Modal.Title>
          </Modal.Header> */}
        <Modal.Body>
          <img src="http://cics.umd.edu/~jdong/misc/cases/c20221122_n21/n21_2022-11-22_asc_01.png"
            width="700" alt="NOAA-21"></img>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
              Close
            </Button> */}
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
