import '../App.css';
import 'bootstrap/dist/css/bootstrap.css'
import { Col, Row, Container } from 'react-bootstrap'
import { useState } from 'react';
import Navbar from './Navbar.js'
import NavRight from './NavRight.js'
import MapComponent from './MapComponent';
import NavRightMsfr from './NavRightMsfr';

const SFR = ({ region }) => {
    const [dates, setDates] = useState(null);
    const [satellites, setSatellites] = useState(
        new Array(10).fill(true)
    );

    const getParams = (params) => {
        setDates(params.dates)
        setSatellites(params.satellites)
    }

    return (
        <div className="App">
            <Navbar />
            <Container fluid>
                <Row style={{ flexWrap: "nowrap" }}>
                    <Col style={{ backgroundColor: "rgb(218, 227, 243)", paddingTop: "20px", minWidth: "400px" }}>
                        {region === "msfr" ? <NavRightMsfr getParams={getParams} /> : <NavRight getParams={getParams} region={region} />}
                    </Col>
                    <Col md="auto" style={{ padding: "0px" }}>
                        <MapComponent dates={dates} satellites={satellites} region={region} />
                    </Col>
                </Row>
            </Container >
        </div >
    );
}

export default SFR;
